
  import { Action, Getter } from "vuex-class";
  import { Component, Ref, Vue } from "vue-property-decorator";
  import { FormBase, FormError, FormSubmit } from "@/components/forms";
  import { formatErrors } from "@/utils/formatters";
  import { bus } from "@/main";
  import { Task, IndexTaskPayload, TaskPhase } from "@/store/modules/task.store";
  import { activityIsDossier } from "@/utils/model-helpers";

  @Component({
    components: {
      LayoutModal: () => import("@/layouts/components/modal.vue"),
      FormBase,
      FormError,
      FormSubmit,
    },
  })
  export default class RegenerateTasksModal extends Vue {
    @Action("task/regenerate") regenerateTask!: (activity_id: number) => void;
    @Action("task/index") indexTasks!: (payload: IndexTaskPayload) => Promise<Task[]>;
    @Getter("activity/viewing") activity!: Activity;

    @Ref() form!: FormClass;

    show = false;

    activity_id: number | null = null;

    errorResponse: ErrorResponse = {
      status: 0,
      errors: [],
    };

    get isDossier() {
      return activityIsDossier(this.activity);
    }

    mounted() {
      bus.$off("show-regenerate-tasks");
      bus.$on("show-regenerate-tasks", (activity_id: number) => {
        this.show = true;
        this.activity_id = activity_id;
      });
    }

    async submit(form: FormClass) {
      try {
        if (!this.activity_id) {
          return;
        }

        await this.regenerateTask(this.activity_id);

        const query: any = { and: { activity_id: this.activity_id } };

        if (this.isDossier) {
          query.and.not = {
            phase: [TaskPhase.prospect, TaskPhase.dossierClosing],
            group: ["Reminders", "Follow-up", "Overige"],
          };
        } else {
          query.and.or = {
            phase: TaskPhase.prospect,
            group: ["Reminders", "Follow-up", "Overige"],
          };
        }

        await this.indexTasks({ q: query, per_page: 900, s: { ordinal: "asc", created_at: "asc", id: "asc" } });

        this.$toast.open({ message: "De taken werden opnieuw gegenereerd", type: "success", position: "bottom-right" });

        this.handleClose();
      } catch (e) {
        this.errorResponse = formatErrors(e);
      } finally {
        form.reset();
      }
    }

    handleClose() {
      this.errorResponse = {
        status: 0,
        errors: [],
      };

      this.show = false;
    }
  }
